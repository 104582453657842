.event-list {
    color: white;
    border: 0.1vw solid rgba(255, 255, 255, 0.74);
    border-radius: 0.26vw;
    padding: 0.26vw; 
    margin: 0.26vw; 
    background: rgba(0, 0, 0, 0.3);
    justify-content: center;
}

.event {
    border-radius: 0.26vw;
    text-align: center;
    padding: 0.26vw;
    
}

.in {
    background: rgba(130, 220, 255, 0.336);
}

.out {
    background: rgba(138, 94, 181, 0.336);
}

.currently-signed-in {
    background: rgba(101, 255, 101, 0.336);
}

.total {
    display: flex;
    gap: 0.5vw; 
    border-radius: 0.26vw;
    padding: 0.6vw; 
    margin: 0.25vw;
    background: rgba(0, 0, 0, 0.3);
    justify-content: center;
    align-items: center;
}

.total h5 {
    margin: 0;
    padding: 0;
}

.duration {
    color: rgb(83, 255, 97);
}

.event-list-container {
    min-height: 23vh; /* Fits four events at 1080p */
    max-height: 23vh;
    overflow-y: auto;
    list-style: none;
    padding: 0;
}