:root {
    --row-height: 10vh;
    --row-width: calc(100% / 7);
}

.week-view {
    color: white;
    margin-right: 1.2rem;
    width: 98%;
}

.week-view-grid-header .selected {
    background-color: rgba(0, 196, 206, 0.6) !important;
}

.week-view-grid-header :hover{
    border: 2px solid aliceblue;
}

.week-view-grid-header {
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    top: 0;
    background-color: inherit;
    z-index: 1;
    grid-template-columns: repeat(7, 1fr);
    height: var(--row-height);
}

.week-view-grid-header > div {
    border-radius: 1vw;
    border: 2px solid transparent;
    width: var(--row-width);
    text-align: center;
    padding: 1.5vh calc(4.5vw - 2px); /* make room for the border */
    background-color: rgba(255, 255, 255, 0.1);
    display: flex;
    justify-content: center;
}

.week-view-grid-item {
    border: solid 2px rgba(255, 255, 255, 0.25);
    border-left: none;
    border-top: none;
    text-align: center;
}

.no-right-border {
    border-right: none;
}

.no-bottom-border {
    border-bottom: none;
}

.week-view-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-auto-rows: var(--row-height);
    height: 100%;
    position: relative;
    /* border: solid 2px black; */
}

.week-view-grid-container {
    display: flex;
    overflow: auto;
    position: relative;
    height: 95vh;
    grid-template-columns: 5rem 1fr;
    grid-gap: 0;
    /* border: solid 2px blue; */
}

.week-view-grid-container :last-child {
    flex: 1;
}

.no-bottom-border {
    border-bottom: transparent;
}

.no-right-border {
    border-right: transparent; 
}

.times-header {
    width: 3.64vw;
    text-align: right;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    background-color: inherit;
    position: sticky;
    top: 0;
    z-index: 1;
    justify-content: space-between;
}

.times-header > div {
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: var(--row-height);
    min-height: var(--row-height);
}

.times-spacer {
    height: calc(var(--row-height) * 1.5);
}

.time {
    /* height: 100vh; */
    display: flex;
    align-items: center;
    padding-right: 0.5rem;
}

.draggable {
    position: absolute;
    width: calc(var(--row-width) * 0.9);
    border-radius: 5px;
    padding: 5px;
    border: 2px solid aliceblue;
    cursor: pointer;
}

.draggable:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.draggable > div {
    position: absolute;
    cursor: ns-resize;
    right: 0;
    left: 0;
    user-select: none;
}

.event-time-bottom {
    bottom: 0;
}

.event-time-top {
    top: 0;
}