:root {
    /*  change this for scaling  */
    --m: 1rem;
    --scale: -.8%;
    --size: 400%;

    --colorOne: #ec21ff94;
    --colorTwo: #21D4FD94;
    --colorThree: #3b4fff94;
}

.draggable::before {
    content: '';
    background: linear-gradient(90deg, var(--colorOne), var(--colorTwo), var(--colorThree), var(--colorTwo), var(--colorOne));
    position: absolute;
    top: var(--scale);
    left: calc(3 * var(--scale));
    right: calc(3 * var(--scale));
    bottom: var(--scale);
    z-index: -5;
    background-size: 200%;
    animation: animate 2.5s infinite linear;
    filter: blur(calc(0.8 * var(--m)));
    border-radius: inherit;
    transition: all 0.5s ease;
    animation-delay: calc(var(--random-delay) * 1s);
}

.draggable:hover::before {
    scale: 1.04;
}

@keyframes animate {
  0% {background-position: 0}
  100% {background-position: 200%}
}