@import url('https://fonts.googleapis.com/css2?family=Oxygen:wght@300;400;700&display=swap');

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
}

html {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

body {
    width: 100%;
    height: 100%;
    font-family: 'Oxygen', serif !important;
    font-size: 13px;
    background: #092756;
    --color-radial-start: rgba(104, 128, 138, 0.4);
    --color-radial-end: rgba(138, 114, 76, 0);
    --color-linear-top-start: rgba(57, 173, 219, 0.25);
    --color-linear-top-end: rgba(42, 60, 87, 0.4);
    --color-linear-45deg-start: #670d10;
    --color-linear-45deg-end: #092756;

    --radial-gradient: radial-gradient(ellipse at 0% 100%, var(--color-radial-start) 10%, var(--color-radial-end) 40%);
    --linear-gradient-top: linear-gradient(to bottom, var(--color-linear-top-start) 0%, var(--color-linear-top-end) 100%);
    --linear-gradient-45deg: linear-gradient(135deg, var(--color-linear-45deg-start) 0%, var(--color-linear-45deg-end) 100%);

    background:
        -webkit-radial-gradient(ellipse at 0% 100%, var(--color-radial-start) 10%, var(--color-radial-end) 40%),
        -webkit-linear-gradient(to bottom, var(--color-linear-top-start) 0%, var(--color-linear-top-end) 100%),
        -webkit-linear-gradient(135deg, var(--color-linear-45deg-start) 0%, var(--color-linear-45deg-end) 100%);
    background:
        -moz-radial-gradient(ellipse at 0% 100%, var(--color-radial-start) 10%, var(--color-radial-end) 40%),
        -moz-linear-gradient(to bottom, var(--color-linear-top-start) 0%, var(--color-linear-top-end) 100%),
        -moz-linear-gradient(135deg, var(--color-linear-45deg-start) 0%, var(--color-linear-45deg-end) 100%);
    background:
        -ms-radial-gradient(ellipse at 0% 100%, var(--color-radial-start) 10%, var(--color-radial-end) 40%),
        -ms-linear-gradient(to bottom, var(--color-linear-top-start) 0%, var(--color-linear-top-end) 100%),
        -ms-linear-gradient(135deg, var(--color-linear-45deg-start) 0%, var(--color-linear-45deg-end) 100%);
    background:
        -o-radial-gradient(ellipse at 0% 100%, var(--color-radial-start) 10%, var(--color-radial-end) 40%),
        -o-linear-gradient(to bottom, var(--color-linear-top-start) 0%, var(--color-linear-top-end) 100%),
        -o-linear-gradient(135deg, var(--color-linear-45deg-start) 0%, var(--color-linear-45deg-end) 100%);
    background: 
        var(--radial-gradient),
        var(--linear-gradient-top),
        var(--linear-gradient-45deg);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#3E1D6D', endColorstr='#092756', GradientType=1);
}

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

::-webkit-scrollbar-track {
    background: hsla(270, 5%, 80%, 0.418);
    border-radius: 20px;
}

::-webkit-scrollbar-thumb {
    /* background: linear-gradient(rgba(34, 55, 63, 0.643), #a48bbda4);
	 */
    background: hsl(0, 2%, 100%);
    border-radius: 20px;
}

::-webkit-scrollbar-thumb:hover {
    background: #ffffff;
}

::-webkit-scrollbar-corner {
    color: transparent;
}
