.date-selector {
    color: white;
    max-width: min-content;
    user-select: none;
    height: calc(1.2rem * 11.5);
    @media (max-width: 768px) {
        height: calc(1.2rem * 7.5);
    }
}

table {
    border-collapse: collapse;
}

th {
    text-align: left;
}

td {
    /*   border: solid 2px black; */
    min-width: 0.265vw;
    text-align: center;
    padding: .12vw;
    @media (max-width: 768px) {
        font-size: 0.75rem;
        max-width: calc(20% / 7);
    }
}

.day-button {
    border-radius: 40%;
    background-color: rgb(185, 222, 255);
    border: none;
    min-width: 100%;
    cursor: pointer;
}

.day-button:hover {
    background-color: rgb(89, 174, 248);
}

.selected {
    background-color: rgb(0, 136, 255);
}

.today {
    background-color: rgb(185, 255, 213);
    color: black;
}

.other-month {
    opacity: 0.5;
}

.hoverable:hover {
    position: relative;
}

.hoverable:hover::after {
    content: attr(data-day);
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgb(255, 255, 255);
    color: #080808;
    padding: 0.104vw 0.26vw;
    border-radius: .4vw;
    white-space: nowrap;
    z-index: 1;
    pointer-events: none;
}

.arrow {
    float: right;
    border-radius: 20%;
    background-color: transparent;
    border: none;
    max-width: 1.7rem;
    margin-left: .2rem;
    margin-right: .2rem;
    cursor: pointer;
}

.arrow:hover {
    background-color: rgba(155, 155, 155, 0.351);
    border-radius: 50%;
}

.arrow-svg {
    fill: white;
    flex-shrink: 0;
    position: relative;
    top: -1.5px;
}

.month-selector {
    width: 100%;
    display: block;
}