.sidebar {
    width: fit-content;
    max-width: 256px;
    margin: 1.2rem;
    display: flex;
    flex-direction: column;
    grid-row-gap: 1.2rem;
}   

.calendar-container {
    display: flex;
}

.main-content {
    width: 100%;
    margin: 1.2rem;
}

@media (max-width: 768px) {
    .main-content {
        display: none;
    }

    .calendar-container {
        justify-content: center;
    }
}